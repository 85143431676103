.page-title{
  text-align: center;
  line-height:4rem;
  margin-top:4rem;
  &:after{
    content:"";
    display:block;
    width:60px;
    height:0;
    border-bottom:2px $color-eye solid;
    margin:0 auto;
  }
}

.page-content{
  min-height: 500px;
}