$color-text:#1f2326;
$color-text-light:#a1a1a1;
$color-text-lighter:#c2c2c2;
$color-text-grey:#898989;
$color-text-dark:#282828;
$color-eye:#bf1e2e;
$color-eye-daker:#992835;
$color-white:#fff;

$bg-nav:#343a40;
$bg-dark:#272727;
$bg-light:#f5f5f5;
$bd-dark:#2b2b2c;
$bd-light:#e6e6e6;
$bd-line-dark:#3e4044;



@import "model/_common";
@import "model/_dialog";
@import "model/_index";
@import "model/_page";
@import "model/_article";
@import "model/_case";
@import "model/_product";
@import "model/_order";
@import "model/_member";
@import "model/_footer";