.product-list{
  padding:2rem 0;
  .card{
    border-radius:0;
    box-shadow: rgba(0,0,0,.08) 0 0 5px;
    position: relative;
    transition: background-color .3s;
    border:0;
    background:$color-text;
    .card-body{
      position: relative;
      z-index:2;
      h3{
        color:$color-white;
      }
    }
    .mask{
      z-index:1;
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height :100%;
    }
  }
}