
.footer{
  padding-top:70px;
  dd{
    margin-top:1.8rem;
    line-height:1.8rem;
    a{
      display:block;
      color:$color-text-grey;
    }
  }
  hr{
    border-color:$bd-line-dark;
  }
  .copyright-row{
    line-height:40px;
    padding-bottom:20px;
    a{
      color:$color-text-grey;
    }
  }
}
