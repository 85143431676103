.case-list{
  padding:2rem 0;
  .card{
    border-radius:0;
    box-shadow: rgba(0,0,0,.08) 0 0 5px;
    position: relative;
    transition: background-color .3s;
    border:0;
    .card-body{
      position: relative;
      z-index:2;
      h3{
        transition: color .3s;
      }
    }
    .mask{
      background:url(../images/eye.png) center -99px no-repeat rgba(0,0,0,0);
      transition:background-color,background-position .5s;
      z-index:1;
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height :100%;
    }
    &:hover{
      background:$color-text;
      .card-body h3{
        color:$color-white;
      }
      .mask{
        background-color:rgba(0,0,0,.6);
        background-position:center 72px;
      }
    }
  }
}